// Components
import { FormSection } from "./components"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"

// Utils
import { type FormType, validationSchema } from "./utils"

export default function CountInventory(): JSX.Element {
  // Form
  const defaultValues: FormType = {
    count_id: null,
    employee_id: "",
    location_id: "",
    memo: "",
    transaction_date: moment().format("YYYY-MM-DD"),
    trx_code: "",
    countdetail: []
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      <FormSection
        onSuccess={() => {
          // Scroll to Top
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        }}
      />
    </FormProvider>
  )
}
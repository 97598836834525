// Form
import * as yup from "yup"

export const validationSchema = yup.object().shape({
  tax_code: yup.string().label("CODE").required().test('len', 'Must be exactly 2 characters', val => val.toString().length === 2),
  tax_name: yup.string().label("NAME").required().max(100, "Must be 100 characters or less"),
  type_tax_id: yup.string().label("TYPE").required(),
  rate: yup.number().label("RATE").required().test('is-valid-rate', 'Rate must be between -999.9999 and 999.9999', val => val !== undefined && val >= -999.9999 && val <= 999.9999),
  coa_collect_id: yup.string().label("COA COLLECT").required(),
  coa_paid_id: yup.string().label("COA PAID").required(),
  note: yup.string().label("NOTE").defined().max(200, "Must be 200 characters or less"),
})
import { Fragment, useContext } from "react"
import { ChildItem } from "../.."
import { Delete, Update } from "./components"
import type { DataList, DataItem } from "../../../../types"
import { useToggle } from "../../../../../../../../utils"
import { dataContext } from "pages/Setup/Preference/Academy"
import { TableNumber } from "components"

export function Item(props: { item: DataList, index: number, dataList: DataItem, refetch: () => void }) {
  const { isActive } = useToggle(false)
  const { currentPage, limit, size, total } = useContext(dataContext)

  return (
    <Fragment>
      <tr>
        <td>{TableNumber({ dataLength: size, pageCurrent: currentPage, index: props.index, limit: limit, totalData: total })}</td>
        <td>{props.item.academy_code}</td>
        <td>{props.item.academy_name}</td>
        <td>{props.item.description}</td>
        <td>
          <div className="flex items-center justify-center">
            <Update id={props.item} refetch={props.refetch} />
            <Delete id={props.item} refetch={props.refetch} />
          </div>
        </td>
      </tr>
      {isActive && <ChildItem dataList={props.dataList} refetch={props.refetch} />}
    </Fragment>
  )
}
import { Fragment } from "react"
import { ActionButton, Button, Modal } from "components"
import { FormSection } from "../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import { ActionType, ListGroupTask } from "../../types"
import { useApi, useToggle } from "utils"
import { validationSchema, defaultValues } from "../../utils"
import { IsActive } from "./IsActive"

export function Update({ data, refetch }: ActionType) {
  const { isActive, toggle } = useToggle(false)
  const api = useApi()

  const onSubmit = (value: ListGroupTask) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/group-task/edit?mr_group_task_id=${data?.mr_group_task_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
        .then(() => {
          toggle()
          refetch && refetch()
          toggle()
        })
        .catch(() => { })
        .finally(resolve)
    })
  }
  const methods = useForm({
    defaultValues: defaultValues(data!),
    resolver: yupResolver(validationSchema)
  })

  return (
    <Fragment>
      <ActionButton.Update className="join-item" onClick={toggle} />
      {isActive &&
        <Modal isOpen title="UPDATE SHOP TRANSACTION ACCESS" closeModal={toggle}>
          <FormProvider {...methods}>
            {/* @ts-ignore */}
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Modal.Body>
                <FormSection action="UPDATE" />
              </Modal.Body>

              <Modal.Footer className="flex justify-between gap-1">
                <IsActive data={data} refetch={refetch} />
                <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
              </Modal.Footer>
            </form>
          </FormProvider>
        </Modal>
      }
    </Fragment>
  )
}
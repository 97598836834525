// Components
import { BaseTable, Button, Card, ErrorText, HeaderMenu, Loading } from "components"
import { DateFilter, ExpensesActivites, InfoSection, LocationSection, PayableTable, StatementInfo } from "./components"

// Form
import { ErrorMessage } from "@hookform/error-message"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"

// Utils
import { convertNumber, getCompanyName } from "utils"
import { type FormType, getDateFilter, useReceivableList, validationSchema } from "./utils"

export default function Dashboard() {
  // Hooks
  const receivableList = useReceivableList()

  return (
    <section className="container my-5">
      <section className="flex flex-col gap-3">
        <HeaderMenu title="FINANCE DASHBOARD" />
        <FormSection onChangeFilter={receivableList.refetch} />

        <Card>
          <Card.Body>
            <Card.Title>
              <div className="lg:flex">
                <StatementInfo data={[]} />

                <div className="label-text lg:w-[66%]">
                  <section>
                    <b>ACCOUNT RECEIVABLE</b>

                    <div className="lg:w-[45%] ml-5">
                      <InfoSection
                        className="!justify-start"
                        labelClass="lg:ml-5"
                        label="Turnover Ratio"
                        value="-"
                      />
                    </div>

                    <BaseTable>
                      <thead>
                        <tr>
                          <th>NO</th>
                          <th colSpan={2}>INVOICE ID#</th>
                          <th>REF SO ID#</th>
                          <th>DATE</th>
                          <th>CUSTOMER</th>
                          <th>BALANCE</th>
                          <th>7 DAYS</th>
                          <th>15 DAYS</th>
                          <th>21 DAYS</th>
                          <th>30 DAYS</th>
                          {/* <th>30+ DAYS</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {receivableList.isLoading || !receivableList.data.length ? (
                          <tr>
                            <td colSpan={11}>
                            {/* <td colSpan={12}> */}
                              <Loading
                                errorText="No data available"
                                loading={receivableList.isLoading}
                              />
                            </td>
                          </tr>
                        ) : receivableList.data.map((item, key) => {
                          // Functions
                          const getBalanceByAge = (
                            num1: number,
                            num2?: number
                          ): string => {
                            // Vars
                            let result = ""

                            if (num2) {
                              if (item.aged_receivable >= num1 && item.aged_receivable <= num2) {
                                result = convertNumber(item.balance).intoCurrency
                              }
                            } else {
                              if (item.aged_receivable >= num1) {
                                result = convertNumber(item.balance).intoCurrency
                              }
                            }

                            return result
                          }

                          return (
                            <tr key={key}>
                              <td className="w-[200px]">{key + 1}</td>
                              <td className="w-[100px]">{item.trx_code}</td>
                              <td className="w-[100px]">{item.sales_order.trx_code}</td>
                              <td>{moment(item.transaction_date).format("DD MMM YYYY")}</td>
                              <td className="w-[200px]">{item.customer.customer_name}</td>
                              <td className="w-[200px]">{convertNumber(item.balance).intoCurrency}</td>
                              <td>{getBalanceByAge(1, 7)}</td>
                              <td>{getBalanceByAge(8, 15)}</td>
                              <td>{getBalanceByAge(16, 21)}</td>
                              <td>{getBalanceByAge(21, 30)}</td>
                              {/* <td>{getBalanceByAge(30)}</td> */}
                            </tr>
                          )
                        })}
                      </tbody>
                    </BaseTable>
                  </section>

                  <PayableTable data={[]} />
                </div>
              </div>

              <ExpensesActivites data={[]} />
            </Card.Title>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body className="h-[100px]">
            <div className="font-bold">CHART</div>
          </Card.Body>
        </Card>
      </section>
    </section>
  )
}

function FormSection(params: {
  onChangeFilter: (value: FormType) => void
}): JSX.Element {
  // Vars
  const list = getDateFilter()

  // Form
  const onSubmit = (value: FormType) => params.onChangeFilter(value)
  const methods = useForm<FormType>({
    defaultValues: {
      date_end: moment().format("YYYY-MM-DD"),
      date_from: moment().format("YYYY-MM-DD"),
      type: list[0].label,
      location_id: ""
    },
    resolver: yupResolver(validationSchema)
  })
  const { control, formState, handleSubmit, setValue } = methods
  const date_end = useWatch({
    control,
    name: "date_end"
  })
  const date_from = useWatch({
    control,
    name: "date_from"
  })
  const type = useWatch({
    control,
    name: "type"
  })

  return (
    <Card>
      <Card.Body>
        <section  className="flex flex-wrap items-center justify-between gap-2">
          <section>
            <span className="font-bold text-[20px]">{getCompanyName()}</span>

            <div className="mt-3">
                <span>{moment(date_from).format("DD MMMM YYYY")} <b>-</b> {moment(date_end).format("DD MMMM YYYY")}</span>
            </div>
          </section>

          <section className="w-fit flex gap-5 items-center">
            <section className="flex gap-5 items-end">
              <section>
                <DateFilter
                  disabled={type !== "Date"}
                  value={{
                    end_date: date_end,
                    start_date: date_from,
                    type: type
                  }}
                  onChangeEndDate={res => setValue("date_end", res)}
                  onChangeStartDate={res => setValue("date_from", res)}
                  onChangeType={res => setValue("type", res)}
                />

                <ErrorMessage
                  name="type"
                  errors={formState.errors}
                  render={({ message }) => <ErrorText text={message} />}
                />

                <ErrorMessage
                  name="date_from"
                  errors={formState.errors}
                  render={({ message }) => <ErrorText text={message} />}
                />

                <ErrorMessage
                  name="date_end"
                  errors={formState.errors}
                  render={({ message }) => <ErrorText text={message} />}
                />
              </section>

              <FormProvider {...methods}>
                <LocationSection />
              </FormProvider>

              <section>
                <Button
                  type="submit"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </Button>
              </section>
            </section>
          </section>
        </section>
      </Card.Body>
    </Card>
  )
}
// React
import { useContext, useEffect, useState } from "react"

// Components
import { BaseTable, Button, Card, ErrorText, HeaderMenu, Input } from "components"
import { Acc, Date, Deliver, Freight, Memo, PromisedDate, Supplier, SupplierId, TabSection, TableSection, TaxInclusive, TradingTerm } from "../../components"
import { Code } from "./Code"
import { Detail } from "./Detail"
import { Journal, Payment, Purchases } from "./displays"

// Contexts
import { DataJournalContext } from "contexts"
import { ProductContext } from "../../PurchaseOrder/components/DetailBody/contexts"
import { DataBillContext, DataContext, ProbilContext, ProductUnitProvider } from "../contexts"

// Form
import { useFieldArray, useFormContext, useWatch } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

// Icons
import { TbPlus } from "react-icons/tb"

// Types
import type { DetailFormType, FormType } from "../utils"

// Utils
import { useProject, useTaxCode } from "utils"
import { duplicateChecker, tab_list } from "../utils"
import { useProduct } from "../../PurchaseOrder/components/DetailBody/utils"

export function InputSection(params: {
  actionsection?: JSX.Element
}): JSX.Element {
  // Hooks
  const bill = useContext(DataBillContext)
  const project = useProject()
  const tax_code = useTaxCode()
  const dataBill = useContext(DataContext)
  const { data, isLoading, refetch } = useProduct()

  // Vars
  const isPending: boolean = dataBill.po_delivery_status === 1
  
  // Form
  const { control, formState, getValues } = useFormContext<FormType>()
  const vendor_id = useWatch({
    control,
    name: "vendor_id"
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: "probildetail"
  })

  useEffect(() => {
    if (vendor_id) {
      refetch(vendor_id)
    }

    // eslint-disable-next-line
  }, [vendor_id])

  return (
    <section className="container my-5 flex flex-col gap-2">
      <HeaderMenu title="DATA ENTRY | DELIVERY BILL">
        <Code value={getValues("trx_code")} />
      </HeaderMenu>

      <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3 items-end">
        <Supplier
          disabled
          name="vendor_id"
          trading_term_name="payment_term"
          vendor_name="_vendor_name"
        />

        <TradingTerm name="payment_term" />
        <TaxInclusive name="tax_inclusive" />
      </section>

      <Card>
        <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
          <section className="flex flex-col gap-3">
            <Deliver
              deliver_name="location_id"
              detail_name="address"
            />

            <Memo name="memo" />
            <LinkToPurchase />
          </section>

          <section className="flex flex-col gap-3">
            <Date
              name="transaction_date"
              disabled={!isPending}
            />

            <SupplierId
              name="referensi"
              disabled={!isPending}
            />

            <PromisedDate
              disabled
              name="promise_date"
            />

            <Delivery />

            <section className="flex lg:justify-end">
              <Button className="w-fit">
                <TbPlus /> ADD REMINDER
              </Button>
            </section>
          </section>
        </Card.Body>
      </Card>

      <DataJournalContext.Provider value={{ project, tax_code }}>
        <ProductContext.Provider value={{ data, isLoading }}>
          <ProductUnitProvider>
            <BaseTable>
              <thead>
                <tr>
                  <th>BACKORDER</th>
                  <th>DELIVERED</th>
                  <th>ITEM CODE</th>
                  <th>DESCRIPTION</th>
                  <th>UNIT</th>
                  <th>PRICE</th>
                  <th>DISC (%)</th>
                  <th>TOTAL</th>
                  <th>JOB</th>
                  <th>TAX</th>
                  <th>ACTION</th>
                </tr>
              </thead>

              <tbody>
                {fields.map((_, key) => (
                  <Detail
                    key={key}
                    index={key}
                    remove={remove}
                  />
                ))}
              </tbody>
            </BaseTable>
          </ProductUnitProvider>

          <ErrorMessage
            name="probildetail"
            errors={formState.errors}
            render={({ message }) => <ErrorText text={message} />}
          />

          <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
            <section className="flex flex-col gap-3">
              <PaymentStatus />
              <Freight name="freight" />
            </section>

            <Acc
              name={{
                detail: "probildetail",
                freight: "freight"
              }}
            />
          </section>

          <ProbilContext.Provider value={{
            append: (value: DetailFormType) => {
              // Vars
              const data = duplicateChecker(value, getValues("probildetail"), bill.approval_status === "Approved")

              if (data) {
                append(value)
              }
            }
          }}>
            <Tab />
          </ProbilContext.Provider>
        </ProductContext.Provider>
      </DataJournalContext.Provider>

      {params.actionsection}
    </section>
  )
}

function Delivery(): JSX.Element {
  // Form
  const { getValues } = useFormContext<FormType>()

  return (
    <Input
      disabled
      label="DELIVERY"
      value={`#${getValues("delivery_no")}`}
    />
  )
}

function LinkToPurchase() {
  // Hooks
  const data = useContext(DataContext)

  return (
    <Input
      disabled
      label="LINK TO PURCHASE ID"
      value={data.trx_code}
    />
  )
}

function PaymentStatus(): JSX.Element {
  // Hooks
  const dataBill = useContext(DataBillContext)

  return (
    <TableSection>
      <TableSection.Row
        label="BILL STATUS"
        value={dataBill.bill_status}
      />

      <TableSection.Row
        label="APPROVE STATUS"
        value={dataBill.approval_status}
      />

      <TableSection.Row
        label="APPROVED BY"
        value={dataBill.approved_by}
      />
    </TableSection>
  )
}

function Tab(): JSX.Element {
  // Vars
  const content = [Purchases, Payment, Journal]

  // Hooks
  const [currentTab, setCurrentTab] = useState<number>(0)

  return (
    <section>
      <TabSection
        activeIndex={currentTab}
        list={tab_list}
        onChangeTab={setCurrentTab}
      />

      {content.map((Element, key) => {
        if (currentTab === key) {
          return <Element key={key} />
        }

        return null
      })}
    </section>
  )
}
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';
import { ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// register chart
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
  );

export function AreaChart(props: {
    className?: string,
    value: number[],
    label: string[],
    fill?: boolean | "start" | "end" ,
    backgroundColor?: "yellow" | "purple" | "blue",
    borderColor?: "yellow" | "purple" | "blue" ,   
    labelContent: string
}) {

    const backgroundColor = {
        yellow: 'rgba(255, 165, 0, 0.2)',
        purple: 'rgba(128, 0, 128, 0.2)',
        blue: 'rgba(0, 0, 255, 0.2)'
    }

    const borderColor = {
        yellow: 'rgb(255, 165, 0)',
        purple: 'rgb(128, 0, 128)',
        blue: 'rgb(0, 0, 255)'
    }

    const data = {
        labels: props.label ? props.label : [''],
        datasets: [{
            label: props.labelContent ? props.labelContent : '',
            data: props.value ? props.value : [0],
            backgroundColor: props.backgroundColor ? backgroundColor[props.backgroundColor as keyof typeof backgroundColor] : backgroundColor.yellow,
            borderColor: props.borderColor ? borderColor[props.borderColor as keyof typeof borderColor] : borderColor.yellow,
            hidden: false,
            fill: props.fill ? props.fill : false,
        },],
    };

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    callback: function (value: any) {
                        return this.chart.data.datasets[0].data.includes(value) ? value : null;
                    }
                }
            },
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: true,
                },
            },

        },
        plugins: {
            filler: {
                propagate: false
            },
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        interaction: {
            intersect: false,
        },
    };

    return (
        <div className={props.className || "chart-container"}>
            <Line data={data} options={options} />
        </div>
    );
}

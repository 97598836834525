// React
import { Fragment, useContext, useEffect } from "react"

// Components
import { Checkbox, HintList, Input, Label, Modal, Select } from "components"

// Configs
import { theme } from "configs"

// Contexts
import { LocationContext } from "contexts"
import { RestrictedUpdateContext } from "../../contexts"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Types
import type { SelectOptionType } from "types"
import type { FormType } from "../../types"

// Utils
import { normal_post_list, useAccountGroup, useAccountType, useCoaCode } from "./utils"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  parent_id?: number
}) {
  // Vars
  const header_level: SelectOptionType[] = [
    {
      label: "Header",
      value: "1"
    },
    {
      label: "Sub Header",
      value: "2"
    },
    {
      label: "Detail",
      value: "3"
    }
  ]

  // Hooks
  const restricted = useContext(RestrictedUpdateContext)

  // Form
  const { control, getValues, setValue } = useFormContext<FormType>()

  return (
    <Modal.Body
      hintText={(
        <Fragment>
          <div className="text-xl font-bold mb-3">How Account Types Affect Your Reports:</div>
          <div className="mb-3">Account types are crucial because they determine how transactions are recorded in the accounts and ultimately how they affect the financial statements:</div>

          <ul className="pl-4 list-disc mb-3">
            <li><strong>Impact on Financial Statements: </strong>Each account type affects different parts of the financial statements. For example, "Revenue" types increase the income on the Profit & Loss Statement, while "Asset" types might affect the Balance Sheet.</li>
            <li><strong>Regulatory Compliance: </strong>Proper categorization ensures compliance with accounting standards and regulations, as financial reports must reflect accurate and clear financial activity.</li>
            <li><strong>Decision Making: </strong>By segregating financial data into specific account types, companies can better analyze their financial health, track performance, and make informed decisions. For instance, knowing how much is spent on "Fixed Assets" versus "Operating Expenses" can influence budgeting and investment strategies.</li>
            <li><strong>Tax Reporting: </strong>Accurate account typing ensures that all transactions are appropriately recorded for tax purposes, affecting deductions, liabilities, and overall tax compliance.</li>
          </ul>

          <div className="mb-3">Proper setup and management of these data attributes in your ERP system's Chart of Accounts enable sophisticated financial tracking, compliance with accounting standards, and insightful financial reporting.</div>

          <HintList
            list={[
              {
                label: "Account Group (drop-down)",
                list: [
                  {
                    label: "Definition",
                    value: "Categorizes accounts into broader financial classifications such as assets, liabilities, equity, revenue, and expenses."
                  },
                  {
                    label: "Purpose",
                    value: "Helps in organizing accounts for better financial management and reporting. It facilitates the grouping of similar accounts for summary reports and financial analysis."
                  },
                  {
                    label: "Example",
                    value: 'Examples include "Assets," "Liabilities," "Equity," "Revenue," "Expenses."'
                  }
                ]
              },
              {
                label: "Account Type (drop-down)",
                list: [
                  {
                    label: "Definition",
                    value: "Further categorizes accounts within each group to specify the nature of the account."
                  },
                  {
                    label: "Purpose",
                    value: "Enhances the granularity of financial tracking and reporting. Different types dictate how transactions are recorded and how they impact financial statements."
                  },
                  {
                    label: "Options",
                    value: 'Under "Assets," types might include "Current Assets," "Fixed Assets," "Inventory." Under "Liabilities," types could include "Current Liabilities," "Long-Term Liabilities."'
                  }
                ]
              },
              {
                label: "Header Level (drop-down)",
                list: [
                  {
                    label: "Definition",
                    value: "Indicates whether the account is used as a summary header or for detailed transactions."
                  },
                  {
                    label: "Purpose",
                    value: "Organizes the chart of accounts into a hierarchical structure, allowing for summarized information at higher levels and detailed transactions at lower levels."
                  },
                  {
                    label: "Options",
                    value: '"Subheader" (used for grouping related accounts), "Detail" (used for entering actual financial transactions).'
                  }
                ]
              },
              {
                label: "Account Number",
                list: [
                  {
                    label: "Definition",
                    value: "A unique numeric or alphanumeric identifier for each account."
                  },
                  {
                    label: "Purpose",
                    value: "Facilitates easy identification, reference, and data entry. Ensures that each account can be distinctly recognized and accessed."
                  },
                  {
                    label: "Example",
                    value: '"1010" for Cash, "2010" for Accounts Payable.'
                  }
                ]
              },
              {
                label: "Account Name",
                list: [
                  {
                    label: "Definition",
                    value: "The descriptive name given to the account."
                  },
                  {
                    label: "Purpose",
                    value: "Provides clarity and context for what the account is used for, aiding in proper transaction recording and reporting."
                  },
                  {
                    label: "Example",
                    value: '"Cash on Hand," "Sales Revenue."'
                  }
                ]
              },
              {
                label: "Note",
                list: [
                  {
                    label: "Definition",
                    value: "Any additional information relevant to the account."
                  },
                  {
                    label: "Purpose",
                    value: "Can include details about the account's use, restrictions, or specific considerations important for accurate accounting."
                  },
                  {
                    label: "Example",
                    value: '"Use for emergency funds only," "Reconcile monthly."'
                  }
                ]
              },
              {
                label: "Cheque Account",
                list: [
                  {
                    label: "Definition",
                    value: "A checkbox indicating whether the account is used for transactions involving cheques."
                  },
                  {
                    label: "Purpose",
                    value: "Identifies accounts that handle cheque payments or receipts, important for cash management and bank reconciliations."
                  },
                  {
                    label: "Example",
                    value: 'Checkbox marked "Yes" if it involves cheques, "No" otherwise.'
                  }
                ]
              }
            ]}
          />
        </Fragment>
      )}
    >
      <table className="table">
        <tbody>
          <AccountGroup isDisabled={Boolean(props.isUpdate || props.isDetail)} />

          <Controller
            control={control}
            name="report_type"
            render={({ field }) => (
              <tr>
                <td>
                  <Label text="REPORT TYPE" />
                </td>

                <td>
                  <Input
                    disabled
                    value={field.value ?? ""}
                  />
                </td>
              </tr>
            )}
          />

          <AccountType isDisabled={getValues("coa_level") === "1" || Boolean(props.isUpdate || props.isDetail)} />

          <tr>
            <td>
              <Label text="PARENT" />
            </td>

            <td>
              <Input
                disabled
                value={getValues("parent_name")}
              />
            </td>
          </tr>

          <tr>
            <td>
              <Label text="COA LEVEL" />
            </td>

            <td>
              <Input
                disabled
                value={getValues("coa_level")}
              />
            </td>
          </tr>

          <Controller
            control={control}
            name="header_level"
            render={({ field, fieldState }) => {
              // Vars
              const list = header_level.filter(item => {
                if (parseInt(getValues("coa_level")) > 1) {
                  if (parseInt(getValues("coa_level")) === 2) {
                    return parseInt(item.value) === 2
                  }

                  return parseInt(item.value) > 1
                }

                return true
              })

              return (
                <tr>
                  <td>
                    <Label
                      text="HEADER LEVEL"
                      error={Boolean(fieldState.error)}
                    />
                  </td>

                  <td>
                    <Select
                      placeholder="Choose Header Level"
                      isDisabled={restricted || getValues("coa_level") === "1" || props.isDetail}
                      error={fieldState.error?.message}
                      options={list}
                      value={list.find(item => item.value === field.value)}
                      onChange={e => {
                        setValue("cheque_coa", false)
                        setValue("opening_balance", 0)
                        field.onChange(e?.value)
                      }}
                    />
                  </td>
                </tr>
              )
            }}
          />

          {props.isUpdate || props.isDetail ? (
            <Controller
              control={control}
              name="coa_code"
              render={({ field, fieldState }) => (
                <tr>
                  <td>
                    <Label
                      text="ACCOUNT NUMBER"
                      error={Boolean(fieldState.error)}
                    />
                  </td>

                  <td>
                    <Input
                      {...field}
                      ref={null}
                      disabled={props.isDetail}
                      error={fieldState.error?.message}
                      onChange={e => field.onChange(e.target.value.toUpperCase())}
                    />
                  </td>
                </tr>
              )}
            />
          ) : (
            <CoaCode
              isDisabled={Boolean(props.isDetail)}
              isUpdate={props.isUpdate}
              parent_id={props.parent_id}
            />
          )}

          <Controller
            control={control}
            name="coa_name"
            render={({ field, fieldState }) => (
              <tr>
                <td>
                  <Label
                    text="ACCOUNT NAME"
                    error={Boolean(fieldState.error)}
                  />
                </td>

                <td>
                  <Input
                    {...field}
                    ref={null}
                    disabled={props.isDetail}
                    error={fieldState.error?.message}
                    onChange={e => field.onChange(e.target.value.toUpperCase())}
                  />
                </td>
              </tr>
            )}
          />

          <Controller
            control={control}
            name="note"
            render={({ field, fieldState }) => (
              <tr>
                <td>
                  <Label
                    text="NOTE"
                    error={Boolean(fieldState.error)}
                  />
                </td>

                <td>
                  <Input
                    {...field}
                    ref={null}
                    disabled={restricted || props.isDetail}
                    error={fieldState.error?.message}
                    value={field.value ?? ""}
                  />
                </td>
              </tr>
            )}
          />

          <Location
            isCreate={!Boolean(props.isDetail || props.isUpdate)}
            isDisabled={Boolean(props.isDetail || Boolean(props.isUpdate && getValues("header_level") !== "3"))}
          />

          <tr>
            <td />

            <td>
              <CheckboxSection isDisabled={Boolean(props.isDetail)} />
            </td>
          </tr>
        </tbody>
      </table>
    </Modal.Body>
  )
}

function AccountGroup(props: { isDisabled: boolean }) {
  // Form
  const { control, getValues, setValue } = useFormContext<FormType>()

  // Hooks
  const { data, isLoading } = useAccountGroup()

  return (
    <Controller
      control={control}
      name="group_coa_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="ACCOUNT GROUP"
              error={Boolean(fieldState.error)}
            />
          </td>

          <td>
            <Select
              placeholder="Choose Account Group"
              isDisabled={getValues("coa_level") !== "1" || props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value)}
              onChange={e => {
                // @ts-ignore
                setValue("group_coa_code", e?.group_coa_code)
                // @ts-ignore
                setValue("report_type", e?.report_type)
                setValue("account_type_id", "")
                setValue("cheque_coa", false)
    
                if (e) {
                  // @ts-ignore
                  setValue("normal_pos", normal_post_list[e?.group_coa_code as string])
                }
    
                field.onChange(e?.value)
              }}
            />
          </td>
        </tr>
      )}
    />
  )
}

function AccountType(props: { isDisabled: boolean }) {
  // Form
  const { control } = useFormContext<FormType>()
  const group_coa_id = useWatch({
    control,
    name: "group_coa_id"
  })
  const coa_level = useWatch({
    control,
    name: "coa_level"
  })

  // Hooks
  const { data, isLoading, refetch } = useAccountType()

  useEffect(() => {
    refetch(group_coa_id)

    // eslint-disable-next-line
  }, [group_coa_id])

  return (
    <Controller
      control={control}
      name="account_type_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="ACCOUNT TYPE"
              error={Boolean(fieldState.error)}
            />
          </td>

          <td>
            <Select
              placeholder="Choose Account Type"
              isDisabled={props.isDisabled || coa_level !== "2"}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value) ?? null}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}

function CheckboxSection(props: { isDisabled: boolean }) {
  // Form
  const { control } = useFormContext<FormType>()
  const group_coa_code = useWatch({
    control,
    name: "group_coa_code"
  })

  if (group_coa_code === "01") {
    return (
      <Fragment>
        <Controller
          control={control}
          name="cheque_coa"
          render={({ field, fieldState }) => (
            <Checkbox
              {...field}
              ref={null}
              disabled={props.isDisabled}
              error={fieldState.error?.message}
              onChange={() => field.onChange(!field.value)}
            >
              CHEQUE ACCOUNT
            </Checkbox>
          )}
        />
      </Fragment>
    )
  } else {
    return <div />
  }
}

function CoaCode(props: {
  isDisabled: boolean
  isUpdate?: boolean
  parent_id?: number
}) {
  // Form
  const { control, setValue } = useFormContext<FormType>()
  const group_coa_id = useWatch({
    control,
    name: "group_coa_id"
  })

  // Hooks
  const { generate } = useCoaCode(props.parent_id)

  useEffect(() => {
    if (!props.isDisabled && !props.isUpdate && group_coa_id) {
      generate(group_coa_id).then(res => setValue("coa_code", res))
    }

    // eslint-disable-next-line
  }, [group_coa_id])

  return (
    <Controller
      control={control}
      name="coa_code"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="ACCOUNT NUMBER"
              error={Boolean(fieldState.error)}
            />
          </td>

          <td>
            <section>          
              <section className="flex gap-1">
                <section className="w-16">
                  <Controller
                    control={control}
                    name="group_coa_code"
                    render={({ field }) => <Input disabled value={field.value} />}
                  />
                </section>

                <section className="grow min-w-[0px] w-[1px]">
                  <Input {...field} ref={null} disabled={props.isDisabled} />
                </section>
              </section>

              {fieldState.error && (
                <label className="label">
                  <span className="label-text-alt font-bold text-error">{fieldState.error.message}</span>
                </label>
              )}
            </section>
          </td>
        </tr>
      )}
    />
  )
}

function Location(props: {
  isCreate: boolean
  isDisabled: boolean
}) {
  // Form
  const { control, getValues, setValue } = useFormContext<FormType>()

  // Hooks
  const { data, isLoading } = useContext(LocationContext)

  useEffect(() => {
    if (!getValues("location_id") && data && props.isCreate) {
      setValue("location_id", data.find(item => item.label === theme.location_name)?.value ?? "")
    }

    // eslint-disable-next-line
  }, [data])

  return (
    <Controller
      control={control}
      name="location_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="LOCATION"
              error={Boolean(fieldState.error)}
            />
          </td>

          <td>
            <Select
              placeholder="Choose Location"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value)}
              onChange={e => {
                if (e) {
                  field.onChange(e.value)
                }
              }}
            />
          </td>
        </tr>
      )}
    />
  )
}
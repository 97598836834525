import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ChartOptions } from 'chart.js';

// register chart
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function ProfitOrLose(props: { className?: string }) {
  const data = {
    labels: ['Jan - Oct 2024', 'Jan - Oct 2024'],
    datasets: [
      {
        label: 'Sales revenue: 15,658,773',
        data: [-366, -50],
        backgroundColor: ['rgb(167, 230, 255)', 'rgb(216, 191, 216)'],
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, 
        },
        ticks: {
          display: true, 
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true, 
        grid: {
          color: (context) => context.tick.value === 0 ? 'rgba(0, 0, 0, 0.6)' : 'transparent',
        },
        ticks: {
          display: false,
        },
        border: {
          display: false, 
        },
      },
    },
  };

  return (
    <div className={props.className || "chart-container"}>
      <Bar data={data} options={options} />
    </div>
  );
};

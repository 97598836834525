// React
import { Fragment } from "react"

// Components
import { Card, CommandButton } from "components"

// Types
import type { CommandType } from "types"

export function FooterMenu(params: {
  attachment?: CommandType
  customRightElement?: JSX.Element
  customElement?: {
    attachment?: JSX.Element
    delete?: JSX.Element
    email?: JSX.Element
    export?: JSX.Element
    journal?: JSX.Element
    print?: JSX.Element
    save?: JSX.Element
  }
  delete?: CommandType
  email?: CommandType
  export?: CommandType
  journal?: CommandType
  print?: CommandType
  save?: CommandType
}): JSX.Element {
  return (
    <Card>
      <Card.Body className="flex flex-col gap-5">
        <section className="flex flex-wrap justify-between gap-3">
          <section className="flex flex-wrap gap-3">
            {params.customElement?.delete ? params.customElement.delete : params.delete && <CommandButton {...params.delete} actiontype="delete" />}
            {params.customElement?.print ?? <CommandButton {...params.print} actiontype="print" />}
            {params.customElement?.email ?? <CommandButton {...params.email} actiontype="email" />}
            {params.customElement?.export ?? <CommandButton {...params.export} actiontype="export" />}
            {params.customElement?.journal ? params.customElement.journal : params.journal && <CommandButton {...params.journal} actiontype="journal" />}
            {params.customElement?.attachment ? params.customElement.attachment : params.attachment && <CommandButton {...params.attachment} actiontype="attachment" />}
          </section>

          <section className="flex flex-wrap gap-3">
            {params.customRightElement ?? (
              <Fragment>
                {params.customElement?.save ? params.customElement.save : params.save && <CommandButton {...params.save} actiontype="save" />}
              </Fragment>
            )}
          </section>
        </section>
      </Card.Body>
    </Card>
  )
}
import { Controller, useFormContext } from "react-hook-form"
import { useApi, useToggle } from "utils"
import { ActionButton } from "components"
import { DataForm } from "./DataForm"
import { SKUList } from "../types"
import { convertDate } from "pages/Inventori/Transaction/TransactionJournal/utils"
import { useState } from "react"

interface EditDataTableProps {
  action?: "CREATE" | "UPDATE" | "DETAIL"
  index: number
  refetch?: () => void
}
export const EditDataTable = ({ action, index, refetch }: EditDataTableProps) => {
  const api = useApi()
  const { isActive, toggle } = useToggle(false)
  const { control } = useFormContext<SKUList | any>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  return (
    <Controller
      control={control}
      name={`datavalue.${index}` as "datavalue.0"}
      render={({ field }) => {
        if (isActive) {
          return (
            <DataForm
              defaultValue={field.value}
              method="edit"
              onCancelEditing={toggle}
              refetch={refetch}
            />
          )
        } else {
          return (
            <tr>
              <td className="text-center">{index + 1}</td>
              <td>{field.value.sku ?? "-"}</td>
              <td>{field.value.barcode ?? "-"}</td>
              <td>{field.value.kpid ?? "-"}</td>
              <td className="text-right w-[80px]">{field.value.qty}</td>
              <td>{field.value.retail_price ?? "-"}</td>
              <td>{field.value.created_date ? convertDate(field.value.created_date) : "-"}</td>
              <td>{field.value.expired_date ? convertDate(field.value.expired_date) : "-"}</td>
              {action !== "DETAIL" && <td>
                <section className="flex justify-center">
                  <ActionButton.Update onClick={() => {
                    toggle()
                    localStorage.setItem("pskuId", field.value.product_sku_id)
                  }} />
                  <ActionButton.Delete loading={isLoading ? "true" : undefined} onClick={() => {
                    setIsLoading(true)
                    field?.value?.product_sku_id && api.delete(`/product_sku/${field?.value?.product_sku_id}`)
                    .then(() => {
                      setIsLoading(false)
                      refetch && refetch()
                    })
                  }}/>
                </section>
              </td>}
            </tr>
          )
        }
      }}
    />
  )
}
import { useContext, useEffect } from "react"
import { Button, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import { CreateGroupTask, ActionType } from "../types"
import { useApi } from "../../../../../utils"
import { defaultValues, Render, useCode, validationSchema } from "../utils"

export function CreateModal({ toggleProps, refetch }: ActionType) {
  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) { methods.setValue("mr_group_task_code", dataCode?.code) }
    // eslint-disable-next-line
  }, [dataCode?.code])

  const onSubmit = (value: CreateGroupTask) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/group-task/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => {
        toggleProps && toggleProps()
        refetch && refetch()
        toggle()
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const methods = useForm<CreateGroupTask>({ defaultValues: defaultValues(), resolver: yupResolver(validationSchema) })

  return (
    <Modal isOpen title="SETUP | CREATE SHOP TRANSACTION ACCESS" closeModal={toggleProps}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}
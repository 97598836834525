// React
import { createContext } from "react"

// Types
import type { PurchaseOrderType } from "../../PurchaseOrder/types"

export const DataContext = createContext<PurchaseOrderType>({
  _vendor_name: "",
  address: "",
  approval_status: 0,
  approve_by_name: "",
  delivered: 0,
  freight: 0,
  location_id: "",
  memo: "",
  payment_term: "",
  po_delivery_status: 0,
  po_payment_status_text: "",
  podetail: [],
  promize_date: "",
  referensi: "",
  reminder: null,
  tax_inclusive: false,
  transaction_date: "",
  trx_code: "",
  vendor_id: ""
})
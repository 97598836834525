import { useEffect, useState } from "react"
import type { SuccessFetch } from "types"
import { useApi } from "utils"
import { DataList } from "../types"

export function useList() {
  const api = useApi()
  const [data, setData] = useState<DataList[]>([])
  const [isLoading, setLoading] = useState(false)

  const refetch = () => {
    setLoading(true)

    api.get("/product_sku/?only_active=true") 
    .then((res: SuccessFetch<{ payload: DataList[] }>) => setData(res.data.payload))
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  useEffect(() => {
    setLoading(true)

    api.get("/product_sku/?only_active=true") 
    .then((res: SuccessFetch<{ payload: DataList[] }>) => setData(res.data.payload))
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
      // eslint-disable-next-line
    }, [])

  return { data, isLoading, refetch }
}
// React
import { useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { FormType } from "../vars"

// Utils
import { useApi } from "utils"

// Types
interface Type {
  invoice_id: number
  trx_code: string
  transaction_date: string
  sales_order: {
    sales_order_id: number
    trx_code: string
    referesi: string
  },
  customer: {
    customer_name: string
    customer_id: number
    customer_code: string
  },
  balance: number
  aged_receivable: number
}

export function useReceivableList() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<Type[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const refetch = (params: FormType): void => {
    setLoading(true)

    api.get(
      "/dashboard/transaction/account-receivable/",
      {
        params: {
          ...params,
          location_id: params.location_id !== "" ? params.location_id : null
        }
      }
    ).then((res: SuccessFetch<{ payload: Type[] }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}
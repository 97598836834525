import { ActionButton, BaseTable, Button, Card, Loading, PaginationData, PermissionLink, TableNumber } from "components";
import moment from "moment";
import { ModalDelete } from "pages/Inventori/Transaction/Received/components";
import { Fragment, useState } from "react";
import { IoSendSharp } from "react-icons/io5";
import { APPROVAL, useToggle } from "utils";
import { FilterSection } from "./components";
import { ReceivedType } from "./types";
import { defaultFilter, useList } from "./utils";

interface PaginationState {
  total: number;
  page: number;
  limit: number;
  pages: number;
  keyword: string;
  product_group?: string;
}

export function ItemsReceived() {
  const modalDelete = useToggle(false)
  const [pagination, setPagination] = useState<PaginationState>(defaultFilter)
  const [deleteData, setDeleteData] = useState<ReceivedType | null>(null)
  const { data, isLoading } = useList(pagination)

  const onClickDelete = (data: ReceivedType) => {
    setDeleteData(data)
    modalDelete.setActive(true)
  }

  return (
    <Fragment>
      <section className="container my-2">
        {isLoading
          ? <Loading loading />
          : <>
            <section className="mt-2">
              <Card>
                <Card.Body>
                  <FilterSection
                    selected={pagination}
                    setSelected={setPagination}
                  />
                </Card.Body>
              </Card>
            </section>

            <section className="mt-2">
              <Card>
                <Card.Body>
                  <section className="overflow-x-auto">
                    <BaseTable className="border-collapse">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th colSpan={2}>RECEIVED ID#</th>
                          <th>REF. TRANSFER ID#</th>
                          <th>DATE</th>
                          <th>FROM LOCATION</th>
                          <th>TO LOCATION</th>
                          <th>TRADER</th>
                          <th>APPROVAL</th>
                          <th>MEMO</th>
                          <th>TRANSFERRED</th>
                          <th>RECIEVED</th>
                          <th>REJECTED</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.payload.length === 0
                          ? <tr><td colSpan={14} className="text-center"><Loading errorText="No data available" /></td></tr>
                          : <Fragment>
                            {data?.payload?.map((item, key) => (
                              <tr>
                                <td>{TableNumber({
                                  pageCurrent: pagination?.page,
                                  dataLength: pagination?.limit!,
                                  index: key,
                                  limit: pagination?.limit,
                                  totalData: pagination?.total
                                })}</td>
                                <td className="text-center">
                                  <Button
                                    type="button"
                                    color="ghost"
                                    disabled={[APPROVAL.REJECT, APPROVAL.APPROVE].includes(
                                      item?.approval_status
                                    )}>
                                    <PermissionLink
                                      permission="IT133"
                                      to={`/inventory/transaction/received/edit/${item.received_id}`}
                                    >
                                      <IoSendSharp />
                                    </PermissionLink>
                                  </Button>
                                </td>
                                <td>{item.trx_code}</td>
                                <td>{item.trx_code}</td>
                                <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                                <td>{item.location_from.location_name}</td>
                                <td>{item.location_to.location_name}</td>
                                <td>{item.employee.employee_name}</td>
                                <td>{APPROVAL[item.approval_status]}</td>
                                <td>{item.memo}</td>
                                <td className="text-center">{item.qty_transfer}</td>
                                <td className="text-center">{item.qty_received}</td>
                                <td className="text-center">{item.qty_rejected}</td>
                                <td>
                                  {item.approval_status !== APPROVAL.APPROVE && <ActionButton.Delete
                                    permission="IR014"
                                    className="join-item"
                                    onClick={() => onClickDelete(item)}
                                  />
                                  }
                                </td>
                              </tr>
                            ))}
                          </Fragment>
                        }
                      </tbody>
                    </BaseTable>
                  </section>

                  <div className="mt-5">
                    <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
                  </div>
                </Card.Body>
              </Card>

              <ModalDelete
                modalState={modalDelete}
                data={{
                  received_id: deleteData?.received_id ?? NaN,
                  transaction_date: deleteData?.transaction_date ?? "",
                  trx_code: deleteData?.trx_code ?? "",
                  location_from_name: deleteData?.location_from.location_name ?? "",
                  location_to_name: deleteData?.location_to?.location_name ?? "",
                  employee_name: deleteData?.employee?.employee_name ?? "",
                  memo: deleteData?.memo ?? "",
                  quantity_received: deleteData?.qty_received ?? 0
                }}
                onSuccess={() => {
                  modalDelete.setActive(false)
                  setDeleteData(null)
                }}
              />

            </section>
          </>
        }
      </section>
    </Fragment>
  )
}
import { Fragment, useEffect } from "react"
import { DataTable } from "./components/DataTable"
import { FormProvider, useForm } from "react-hook-form"
import { SKUList } from "./types"
import { useList } from "./hooks"

export function TabSKU({ disable }: { disable?: boolean }) { 
  const { data, isLoading, refetch } = useList()
  const methods = useForm<SKUList>({ defaultValues: { datavalue: [] } })

  useEffect(() => {
    if (data) {
      methods.reset({ datavalue: data })
    }
  }, [data, methods])

  return (
    <Fragment>
      <section className="mt-2x">
        <FormProvider {...methods}>
          {/* @ts-ignore */}
          <DataTable isLoading={isLoading} refetch={refetch} />
        </FormProvider>
      </section>
    </Fragment>
  )
}

// React
import { Fragment } from "react"

// Components
import { ApproveAction } from "./ApproveAction"

// Icons
// Third-Party Libraries
import moment from "moment"

// Utils
import { statusMap } from "../utils"

export function Item(props: {
    data: any,
    index: number
    refetch: () => void
}) {
    return (
        <Fragment>
            <tr key={props.index}>
                <td>{props.index + 1}</td>
                <td>
                    <ApproveAction data={props.data} refetch={props.refetch} />
                </td>
                <td>{props.data.trx_code}</td>
                <td>{props.data.transaction_date ? moment(props.data.transaction_date).format("DD/MM/YYYY") : ""}</td>
                <td>{props.data.vendor_name}</td>
                <td>{props.data.location_name}</td>
                <td>{props.data.memo}</td>
                <td>{statusMap[props.data.approve_status]}</td>
                <td>{props.data.approve_date ? moment(props.data.approve_status).format("DD/MM/YYYY") : ""}</td>
            </tr>
        </Fragment>
    )
}
import { Card } from "components";
import { CardSection } from "../CardSection";
import { AreaChart, ProfitOrLose } from "../GrafikSection";
import { PiArrowDownBold, PiArrowUpBold } from "react-icons/pi";

export function ProfitAndLost() {
    return (
        <section>
            <span className="font-bold text-[15px]">Profitablity</span>
            <div className="grid lg:grid-cols-3 mt-2 gap-4">
                <div className="flex-grow">
                    <Card className="flex-grow h-full">
                        <Card.Body>
                            <CardSection
                                label="Profit Or Lose"
                                value="3,664,719,902"
                                subLabel="149.9 % from 1 Jan - 3 Oct 2023"
                                persentase={-149.9}
                            >
                                <div className="mt-[50px]">
                                    <ProfitOrLose />
                                </div>
                                <div className="mt-14">
                                    No profit was made because the expenses were higher than the income.
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
                <div className="flex flex-col gap-2 lg:col-span-2">
                    <Card className="lg:h-[260px]">
                        <Card.Body>
                            <CardSection
                                label="Income"
                                value="15,658,773"
                                subLabel="6278.5 % from 1 Jan - 3 Oct 2023"
                                persentase={6278.5}
                                className="lg:flex justify-between"
                            >

                                <div className="lg:w-[500px] mt-[50px] lg:mt-0">
                                    <AreaChart
                                        className="lg:h-[235px]"
                                        label={['Jan', 'mar', 'may', 'jul', 'sept']}
                                        value={[400, 100, 200, 600, 300]}
                                        labelContent="Income"
                                        backgroundColor="yellow"
                                        borderColor="yellow"
                                        fill="start"
                                    />
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                    <Card className="lg:h-[260px]">
                        <Card.Body>
                            <div className="lg:flex justify-between">
                                <div>
                                    <div className="text-md text-slate-600 font-bold">Expenses</div>
                                    <div className="text-2xl font-bold my-2">15,658,773</div>
                                    <div className="flex items-center gap-1">
                                        {6278.5 < 0 ?
                                            <PiArrowDownBold className="text-red-500" />
                                            : (6278.5 > 0 ? <PiArrowUpBold className="text-green-500" />
                                                : '-')
                                        }
                                        <span>6278.5 % from 1 Jan - 3 Oct 2023</span>
                                    </div>

                                    <div className="mt-7">
                                        <div>Cost Of goods sold: <b>0</b></div>
                                        <div>Operating Expenses: <b>3,680,378,675</b></div>
                                    </div>
                                </div>
                                <div >
                                    <div className="lg:w-[500px] mt-[50px] lg:mt-0">
                                        <AreaChart
                                            className="lg:h-[235px]"
                                            label={['Jan', 'mar', 'may', 'jul', 'sept']}
                                            value={[400, 100, 200, 600, 300]}
                                            labelContent="Income"
                                            backgroundColor="yellow"
                                            borderColor="yellow"
                                            fill="start"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </section>
    )
}
// React
import { Fragment, useState } from "react"

// Components
import { BaseTable, Card, FooterMenu, HeaderMenu, Input, Loading, PaginationData, TableHeaderSort, TableNumber } from "../../../../components"
import { Create, Delete, Update } from "./components"

// Third-Party Libraries
import { debounce } from "lodash"

// Types
import type { PaginationState } from "types"
import type { TaxCodeType } from "./types"

// Utils
import { default_pagination_value } from "utils"
import { useList } from "./utils"

type headerState = { title: string, sort: boolean, align?: 'left' | 'right' }

export default function TaxCode() {
  // Hooks
  const [pagination, setPagination] = useState<PaginationState>(default_pagination_value)
  const { data, isLoading, refetch } = useList(pagination)
  const onDebounce = debounce((e: any) => setPagination(prev => {
    return {
      ...prev,
      keyword: e.target.value
    }
  }))

  const dataList: TaxCodeType[] = data.items

  const header: headerState[] = [
    { title: "CODE", sort: true },
    { title: "NAME", sort: true },
    { title: "TYPE", sort: false },
    { title: "RATE", sort: false },
    { title: "COA COLLECT", sort: false },
    { title: "COA PAID", sort: false },
    { title: "NOTE", sort: false },
  ]

  const sortMapping: Record<string, string> = {
    'CODE': 'tax_code',
    'NAME': 'tax_name',
    'TYPE': 'type',
    'RATE': 'rate',
    'COA COLLECT': 'coaCollect',
    'COA PAID': 'coaPaid',
    'NOTE': 'note'
  }
  const handleSort = (item: string, sortType: string) => {
    const sortParam = sortMapping[item] ? `${sortMapping[item]}:${sortType}` : '';
    setPagination({ ...pagination, sort: sortParam, page: 1 })
  }

  return (
    <section className="container gap-5 flex flex-col">
      <HeaderMenu title="TAX CODE" />

      <Card>
        <Card.Body className="flex-row justify-end items-center">
          <Create onSuccess={refetch} />
        </Card.Body>

        <Card.Body>
          <div className="flex">
            <div className="mb-3">
              <Input
                label="SEARCH"
                className="md:max-w-[300px] w-full"
                placeholder="Search..."
                onChange={onDebounce}
              />
            </div>
          </div>
          <BaseTable>
            <thead>
              <tr>
                <th>NO</th>
                <TableHeaderSort
                  title={header}
                  onSort={(item, sortType) => handleSort(item, sortType)}
                />
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {isLoading || dataList.length === 0 ? (
                <tr>
                  <td colSpan={9} className="text-center">
                    <Loading loading={isLoading} errorText="No data available" />
                  </td>
                </tr>
              ) : (
                <Fragment>
                  {dataList.map((item, key) => (
                    <tr key={key}>
                      <td className="text-center">{TableNumber({ dataLength: data.pagination_data.size, pageCurrent: pagination.page, index: key, limit: pagination.limit, totalData: pagination.total })}</td>
                      <td>{item.tax_code}</td>
                      <td>{item.tax_name}</td>
                      <td>{item.type_tax_name}</td>
                      <td>{item.rate}</td>
                      <td>{item.coa_collect_coa_code} {item.coa_collect_coa_name}</td>
                      <td>{item.coa_paid_grp_code}-{item.coa_paid_coa_code} {item.coa_paid_coa_name}</td>
                      <td>{item.note}</td>
                      <td>
                        <section className="flex justify-center">
                          <Update item={item} onSuccess={refetch} />
                          <Delete item={item} onSuccess={refetch} />
                        </section>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              )}
            </tbody>
          </BaseTable>
          <div className="mt-5">
            <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
          </div>
        </Card.Body>
      </Card>
      <FooterMenu/>
    </section>
  )
}
export const versionList: {
  description: string[]
  version: string
}[] = [
  {
    version: "1.05",
    description: [
      "Menyetarakan tinggi form pada Employee, Item, Vendor & Customer",
      "Selalu tampilkan scroll bar"
    ]
  },
  {
    version: "1.06",
    description: ["Tampilkan status privilege pada navbar layout"]
  },
  {
    version: "1.07",
    description: ["Tampilkan dokumentasi versi pada sistem"]
  },
  {
    version: "1.08",
    description: ["Sembunyikan menu System Setup"]
  },
  {
    version: "1.09",
    description: ["Hapus pop-up detail & pindahkan fungsi soft delete ke update pop-up di semua tampilan menu preference"]
  },
  {
    version: "1.10",
    description: [
      "Sorting list COA berdasarkan Code",
      "Tambah kolom location pada list COA",
      "Semua COA dapat diubah (dengan kondisi tertentu)"
    ]
  },
  {
    version: "1.11",
    description: [
      "Sembunyikan menu COA Group",
      "Hapus pop-up detail & pindahkan fungsi soft delete ke update pop-up di semua tampilan setup"
    ]
  },
  {
    version: "1.12",
    description: [
      "Tambah Inventory & Account Dashboard",
      "Tambah Analytics",
      "Ubah filter tanggal pada Finance Dashboard"
    ]
  },
  {
    version: "1.13",
    description: ["Tambah graph pada Analytics"]
  }
]
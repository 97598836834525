import { Input, Select, Checkbox, Textarea, Button, DateInput } from "../../../../../../components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import type { ProfileList, ProfileProps } from "../../types"
import { useCOAAsset, useCOACogs, useCOAExpences, useCOAExpencesCogs, useCOAIncome } from "./utils"
import { useEffect } from "react"
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom"
import moment from "moment"

export const FormProfile = ({ action, onSubmit, isActiveSubmit, setItemsProfile, itemsProfile, setDiscountAmound, onlineValue, permission }: ProfileProps) => {
  const { control, getValues, handleSubmit, formState, setValue } = useFormContext<ProfileList>()
  
  const online = useWatch({ control, name: "is_online" })
  const build_item = useWatch({ control, name: "build_item" })
  const buy_this_items = useWatch({ control, name: "buy" })
  const sell_this_items = useWatch({ control, name: "sell" })
  const inventori_this_items = useWatch({ control, name: "inventory" })
  const is_apply = useWatch({ control, name: "is_apply" })

  const isActiveOnlineField = online === true
  const isActiveBuildField = build_item === true
  const isActiveBuyField = buy_this_items === true
  const isActiveSellField = sell_this_items === true
  const isActiveInventoriField = inventori_this_items === true
  const isActiveApplyField = is_apply === true

  useEffect(() => {
    if(onlineValue && isActiveOnlineField){
      setValue("online_description", onlineValue)
    }
    // eslint-disable-next-line
  }, [onlineValue, isActiveOnlineField])

  useEffect(() => {
    setItemsProfile({ 
      ...itemsProfile,  
      buy: isActiveBuyField ? "B" : "",
      sell: isActiveSellField ? "S" : "",
      inventory: isActiveInventoriField ? "I" : ""
    })
    // eslint-disable-next-line
  }, [isActiveBuyField, isActiveSellField, isActiveInventoriField])

  return (
    <div>
      <section>
        <div><b>ITEM STATUS:</b></div>
        <hr />
        <Active isDisabled={Boolean(action === "DETAIL")} />
        <InActive isDisabled={Boolean(action === "DETAIL")} />
        <Demage isDisabled={Boolean(action === "DETAIL")} />
        <BuildItem isDisabled={Boolean(action === "DETAIL")} />
        <div className="flex items-baseline">
          <Online onlineValue={onlineValue} isDisabled={Boolean(action === "DETAIL")} />
          {isActiveOnlineField &&
            <Controller
              control={control}
              name="online_description"
              render={({ field, fieldState }) => 
                <Input 
                  {...field} 
                  placeholder="online description" 
                  className="w-full" 
                  value={field.value ?? onlineValue ?? ""} 
                  error={fieldState.error?.message}
                  disabled={action === "DETAIL"} 
                />
              }
            />
          }
        </div>
        <Shop isDisabled={Boolean(action === "DETAIL")} />

        <div className="mt-5 flex">
          <b>ITEM PROFILE:</b>
          <b className="ml-2 text-black dark:text-primary">
            {`${isActiveBuyField ? "B" : itemsProfile?.buy ?? ""}${isActiveSellField ? "S" : itemsProfile?.sell ?? ""}${isActiveInventoriField ? "I" : itemsProfile?.inventory ?? ""}`}
          </b>
        </div>
        <hr />
        <div className="flex items-baseline mt-2 overflow-x-auto">
          <BuyThisItems isDisabled={Boolean(action === "DETAIL" || isActiveBuildField)} setItemsProfile={setItemsProfile} itemsProfile={itemsProfile} />
          {isActiveBuyField && isActiveSellField && isActiveInventoriField
            ? <SelectCOACogs action={action} isDisabled={Boolean(action === "DETAIL")} />
            : isActiveBuyField && isActiveSellField
            ? <SelectCOAEcpencesCogs action={action} isDisabled={Boolean(action === "DETAIL")} />
            : isActiveBuyField && !isActiveInventoriField
            ? <SelectCOAExpences action={action} isDisabled={Boolean(action === "DETAIL")} />
            : isActiveSellField && isActiveInventoriField
            ? <SelectCOACogs action={action} isDisabled={Boolean(action === "DETAIL")} />
            : <></>
          }
        </div>
        <div className="flex items-baseline overflow-x-auto">
          <SellThisItems isDisabled={Boolean(action === "DETAIL")} setItemsProfile={setItemsProfile} itemsProfile={itemsProfile} />
          {isActiveSellField && <SelectCOAIncome action={action} isDisabled={Boolean(action === "DETAIL")} />}
        </div>
        <div className="flex items-baseline overflow-x-auto">
          <InventoryThisItems isDisabled={Boolean(action === "DETAIL")} setItemsProfile={setItemsProfile} itemsProfile={itemsProfile} />
          {isActiveInventoriField && <SelectCOAAsset action={action} isDisabled={Boolean(action === "DETAIL")} />}
        </div>

        <div className="mt-5"><b>EXPLAINATION</b></div>
        <hr />
        <div className="mt-5">
          <Controller
            control={control}
            name="explanation"
            render={({ field, fieldState }) => 
              <Textarea 
                {...field} 
                placeholder="enter explanation" 
                value={field.value ?? ""} 
                error={fieldState.error?.message}
                disabled={action === "DETAIL"} 
              />
            }
          />
        </div>

        <div className="mt-5"><b>DISCOUNDED ITEM</b></div>
        <hr />
        <Apply isDisabled={Boolean(action === "DETAIL")} />
        {isActiveApplyField &&
          <>
            <Controller
              control={control}
              name="discount_value"
              render={({ field, fieldState }) => 
                <Input 
                  {...field} 
                  type="number"
                  label="VALUE (%)" 
                  placeholder="Input discount" 
                  disabled={Boolean(action === "DETAIL")} 
                  value={field.value ?? ""} 
                  error={fieldState.error?.message}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    action === "CREATE" && setDiscountAmound(e.target.value)
                  }}
                />
              }
            />
            <Controller
              control={control}
              name="discount_start"
              render={({ field, fieldState }) => 
                <DateInput
                  disabled={Boolean(action === "DETAIL")} 
                  label="START DISCOUNT" 
                  placeholderText="Input start date"
                  error={fieldState.error?.message}
                  ref={field.ref}
                  selected={field?.value ? moment(field?.value).toDate() : undefined}
                  onChange={value => field.onChange(moment(value).format("YYYY-MM-DD"))}
                />
              }
            />
          </>
        }
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            onClick={handleSubmit(() => onSubmit(getValues()),(err)=> console.log(err))}
            disabled={isActiveSubmit}
            permission={"IT011"}
          >
            {action === "UPDATE" ? "UPDATE" : "SAVE"}
          </Button>
        </div>
      }
    </div>
  )
}

// ITEM STATUS
function Active(props: { isDisabled: boolean }) {
  const { control, setValue } = useFormContext<ProfileList>()

  return (
    <Controller
      control={control}
      name="active"
      render={({ field, fieldState }) => (
        <div className="mt-5">
          <Checkbox
            className="!m-1"
            {...field}
            ref={null}
            disabled={props.isDisabled}
            error={fieldState.error?.message}
            onChange={(e) => setValue("active", e.target.checked)}
          >
            ACTIVE
          </Checkbox>
        </div>
      )}
    />
  )
}
function InActive(props: { isDisabled: boolean }) {
  const { control, setValue } = useFormContext<ProfileList>()

  return (
    <Controller
      control={control}
      name="inactive"
      render={({ field, fieldState }) => (
        <div>
          <Checkbox
            className="!m-1"
            {...field}
            ref={null}
            disabled={props.isDisabled}
            error={fieldState.error?.message}
            onChange={(e) => setValue("inactive", e.target.checked)}
          >
            IN ACTIVE
          </Checkbox>
        </div>
      )}
    />
  )
}
function Demage(props: { isDisabled: boolean }) {
  const { control, setValue } = useFormContext<ProfileList>()

  return (
    <Controller
      control={control}
      name="damage"
      render={({ field, fieldState }) => (
        <Checkbox
          className="!m-1"
          {...field}
          ref={null}
          disabled={props.isDisabled}
          error={fieldState.error?.message}
          onChange={(e) => setValue("damage", e.target.checked)}
        >
          DEMAGE
        </Checkbox>
      )}
    />
  )
}
function BuildItem(props: { isDisabled: boolean }) {
  const { control, setValue } = useFormContext<ProfileList>()

  return (
    <Controller
      control={control}
      name="build_item"
      render={({ field, fieldState }) => (
        <Checkbox
          className="!m-1"
          {...field}
          ref={null}
          disabled={props.isDisabled}
          error={fieldState.error?.message}
          onChange={(e) => {
            setValue("build_item", e.target.checked)
            setValue("coa_buy", null!)
            setValue("buy", false)
          }}
        >
          BUILD ITEM
        </Checkbox>
      )}
    />
  )
}
function Online(props: { 
  onlineValue?: string
  isDisabled: boolean 
}) {
  const { control, setValue } = useFormContext<ProfileList>()

  const online_description = useWatch({ control, name: "online_description" })
  console.log(online_description)

  return (
    <Controller
      control={control}
      name="is_online"
      render={({ field, fieldState }) => (
          <div className="w-[300px]">
            <Checkbox
              className="!m-1"
              {...field}
              ref={null}
              disabled={props.isDisabled}
              error={fieldState.error?.message}
              onChange={(e) => {
                setValue("is_online", e.target.checked)
                setValue("online_description", e.target.checked ? props?.onlineValue : "")
              }}
            >
              ONLINE
            </Checkbox>
          </div>
      )}
    />
  )
}
function Shop(props: { isDisabled: boolean }) {
  const { control, setValue } = useFormContext<ProfileList>()

  return (
    <Controller
      control={control}
      name="shop"
      render={({ field, fieldState }) => (
        <Checkbox
          className="!m-1"
          {...field}
          ref={null}
          disabled={props.isDisabled}
          error={fieldState.error?.message}
          onChange={(e) => setValue("shop", e.target.checked)}
        >
          SHOP / POS
        </Checkbox>
      )}
    />
  )
}

// ITEM PROFILE
function BuyThisItems(props: { 
  isDisabled: boolean 
  itemsProfile?: any 
  setItemsProfile: (newState: any) => void
}) {
  const { control, setValue } = useFormContext<ProfileList>() 
  
  return (
    <Controller
      control={control}
      name="buy"
      render={({ field, fieldState }) => (
        <div className="mt-5 w-[300px]">
          <Checkbox
            className="!m-1"
            {...field}
            ref={null}
            disabled={props.isDisabled}
            error={fieldState.error?.message}
            onChange={(e) => {
              setValue("buy", e.target.checked)
              setValue("coa_buy", null!)
              props.setItemsProfile({ 
                ...props.itemsProfile, 
                buy: e.target.checked ? "B" : "",
                sell: props.itemsProfile.sell,
                inventory: props.itemsProfile.inventory,
              })
            }}
          >
            <div className="w-[150px]">I Buy This Items</div>
          </Checkbox>
        </div>
      )}
    />
  )
}
function SellThisItems(props: { 
  isDisabled: boolean 
  itemsProfile?: any 
  setItemsProfile: (newState: any) => void
}) {
  const { control, setValue } = useFormContext<ProfileList>()
  return (
    <Controller
      control={control}
      name="sell"
      render={({ field, fieldState }) => (
        <div className="w-[300px]">
          <Checkbox
            className="!m-1"
            {...field}
            ref={null}
            disabled={props.isDisabled}
            error={fieldState.error?.message}
            onChange={(e) => {
              setValue("sell", e.target.checked)
              setValue("coa_sell", null!)
              // props.setItemsProfile({ ...props.itemsProfile, sell: e.target.checked ? "S" : "", action: "sell" })
              props.setItemsProfile({ 
                ...props.itemsProfile, 
                buy: props.itemsProfile.buy,
                sell: e.target.checked ? "S" : "",
                inventory: props.itemsProfile.inventory,
              })
            }}
          >
            <div className="w-[150px]">I Sell This Items</div>
          </Checkbox>
        </div>
      )}
    />
  )
}
function InventoryThisItems(props: { 
  isDisabled: boolean 
  itemsProfile?: any 
  setItemsProfile: (newState: any) => void
}) {
  const { control, setValue } = useFormContext<ProfileList>()
  return (
    <Controller
      control={control}
      name="inventory"
      render={({ field, fieldState }) => (
        <div className="w-[300px]">
          <Checkbox
            className="!m-1"
            {...field}
            ref={null}
            disabled={props.isDisabled}
            error={fieldState.error?.message}
            onChange={(e) => {
              setValue("inventory", e.target.checked)
              setValue("coa_asset", null!)
              // props.setItemsProfile({ ...props.itemsProfile, inventory: e.target.checked ? "I" : "", action: "inventory" })
              props.setItemsProfile({ 
                ...props.itemsProfile, 
                buy: props.itemsProfile.buy,
                sell: props.itemsProfile.sell,
                inventory: e.target.checked ? "I" : "",
              })
            }}
          >
            <div className="w-[150px]">I Inventory This  Items</div>
          </Checkbox>
        </div>
      )}
    />
  )
}
function SelectCOAExpences(props: { 
  action?: "CREATE" | "UPDATE" | "DETAIL"
  isDisabled: boolean 
}) {
  const { control } = useFormContext<ProfileList>()
  const { data, isLoading } = useCOAExpences()
  const navigate = useNavigate()

  return (
    <div className="flex items-baseline w-full">
      <div className="text-right mr-5 min-w-[200px]">Expences:</div>
      <div className="flex items-top">
        <Controller
          control={control}
          name="coa_buy"
          render={({ field, fieldState }) => (
            <Select
              className="my-1"
              controlClass={props?.action !== "DETAIL" ? "!rounded-r-none lg:w-[450px]" : "lg:w-[500px]"}
              placeholder="Select coa expences..."
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          )}
        />
        {props?.action !== "DETAIL" && 
          <div className="mt-1">
            <Button 
              type="button" 
              color="primary" 
              className="!rounded-r-lg" 
              onClick={() => navigate("/accounts/register")}>

              <FaPlus />
            </Button>
          </div>
        }
      </div>
    </div>
  )
}
function SelectCOAEcpencesCogs(props: { 
  action?: "CREATE" | "UPDATE" | "DETAIL"
  isDisabled: boolean 
}) {
  const { control } = useFormContext<ProfileList>()
  const { data, isLoading } = useCOAExpencesCogs()
  const navigate = useNavigate()

  return (
    <div className="flex items-baseline w-full">
      <div className="text-right mr-5 min-w-[200px]">Expences and Cogs:</div>
      <div className="flex items-top">
        <Controller
          control={control}
          name="coa_buy"
          render={({ field, fieldState }) => (
            <Select
              className="my-1"
              controlClass={props?.action !== "DETAIL" ? "!rounded-r-none lg:w-[450px]" : "lg:w-[500px]"}
              placeholder="Select coa expences and cogs..."
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          )}
        />
        {props?.action !== "DETAIL" && 
          <div className="mt-1">
            <Button 
              type="button" 
              color="primary" 
              className="!rounded-r-lg" 
              onClick={() => navigate("/accounts/register")}>

              <FaPlus />
            </Button>
          </div>
        }
      </div>
    </div>
  )
}
function SelectCOACogs(props: { 
  action?: "CREATE" | "UPDATE" | "DETAIL"
  isDisabled: boolean 
}) {
  const { control } = useFormContext<ProfileList>()
  const { data, isLoading } = useCOACogs()
  const navigate = useNavigate()

  return (
    <div className="flex items-baseline w-full">
      <div className="text-right mr-5 min-w-[200px]">Cogs Account Costs:</div>
      <div className="flex items-top">
        <Controller
          control={control}
          name="coa_buy"
          render={({ field, fieldState }) => (
            <Select
              className="my-1"
              controlClass={props?.action !== "DETAIL" ? "!rounded-r-none lg:w-[450px]" : "lg:w-[500px]"}
              placeholder="Select coa cogs..."
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          )}
        />
        {props?.action !== "DETAIL" && 
          <div className="mt-1">
            <Button 
              type="button" 
              color="primary" 
              className="!rounded-r-lg" 
              onClick={() => navigate("/accounts/register")}>

              <FaPlus />
            </Button>
          </div>
        }
      </div>
    </div>
  )
}
function SelectCOAIncome(props: { 
  action?: "CREATE" | "UPDATE" | "DETAIL"
  isDisabled: boolean 
}) {
  const { control } = useFormContext<ProfileList>()
  const { data, isLoading } = useCOAIncome()
  const navigate = useNavigate()

  return (
    <div className="flex items-baseline w-full">
      <div className="text-right mr-5 min-w-[200px]">Income Account Sales:</div>
      <div className="flex items-top">
        <Controller
          control={control}
          name="coa_sell"
          render={({ field, fieldState }) => (
            <Select
              className="my-1"
              controlClass={props?.action !== "DETAIL" ? "!rounded-r-none lg:w-[450px]" : "lg:w-[500px]"}
              placeholder="Select coa income"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          )}
        />
        {props?.action !== "DETAIL" && 
          <div className="mt-1">
            <Button 
              type="button" 
              color="primary" 
              className="!rounded-r-lg" 
              onClick={() => navigate("/accounts/register")}>

              <FaPlus />
            </Button>
          </div>
        }
      </div>
    </div>
  )
}
function SelectCOAAsset(props: { 
  action?: "CREATE" | "UPDATE" | "DETAIL"
  isDisabled: boolean 
}) {
  const { control } = useFormContext<ProfileList>()
  const { data, isLoading } = useCOAAsset()
  const navigate = useNavigate()

  return (
    <div className="flex items-baseline w-full">
      <div className="text-right mr-5 min-w-[200px]">Asset Account Inventory:</div>
      <div className="flex items-top">
        <Controller
          control={control}
          name="coa_asset"
          render={({ field, fieldState }) => (
            <Select
              className="my-1"
              placeholder="Select coa asset"
              controlClass={props?.action !== "DETAIL" ? "!rounded-r-none lg:w-[450px]" : "lg:w-[500px]"}
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          )}
        />
        {props?.action !== "DETAIL" && 
          <div className="mt-1">
            <Button 
              type="button" 
              color="primary" 
              className="!rounded-r-lg" 
              onClick={() => navigate("/accounts/register")}>

              <FaPlus />
            </Button>
          </div>
        }
      </div>
    </div>
  )
}

// DISCOUNDED ITEM
function Apply(props: { isDisabled: boolean }) {
  const { control, setValue } = useFormContext<ProfileList>()

  return (
      <Controller
        control={control}
        name="is_apply"
        render={({ field, fieldState }) => (
          <div className="mt-5">
            <Checkbox
              className="!m-1"
              {...field}
              ref={null}
              disabled={props.isDisabled}
              error={fieldState.error?.message}
              onChange={(e) => {
                setValue("is_apply", e.target.checked)
                setValue("discount_value", "")
                setValue("discount_start", "")
              }}
            >
              Apply  Discounted for Ths Item 
            </Checkbox>
          </div>
        )}
      />
  )
}
import { Fragment, useContext } from "react"
// import { ActionButton } from "../../../../../../../../components"
// import { CreateModal } from "../../../CreateModal"
import { ChildItem } from "../.."
import { Delete, Update } from "./components"
import type { DataItem, ListBank } from "../../../../types"
import { useToggle } from "../../../../../../../../utils"
import { dataContext } from "pages/Setup/Preference/Bank"
import { TableNumber } from "components"

export function Item(props: { item: ListBank, index: number, dataList: DataItem, refetch: () => void}) {
  const { isActive } = useToggle(false)
  const { currentPage, limit, size, total} = useContext(dataContext)

  return (
    <Fragment>
      <tr>
      <td>{TableNumber({dataLength: size, pageCurrent: currentPage, index: props.index, limit: limit, totalData: total})}</td>
        <td>{props.item.bank_code}</td>
        <td>{props.item.bank_name}</td>
        <td>{props.item.description}</td>
        <td>
        <div className="flex justify-center">
            <Update id={props.item} refetch={props.refetch} />
            <Delete id={props.item} refetch={props.refetch} />
          </div>
        </td>
      </tr>
      {isActive && <ChildItem dataList={props.dataList} refetch={props.refetch} />}
    </Fragment>
  )
}

// function Add(props: { item: ListBank }) {
//   const { isActive, toggle } = useToggle(false)

//   return (
//     <Fragment>
//       <ActionButton.Add className="join-item" onClick={toggle} />

//       {isActive && (
//         <CreateModal
//           bank_code={props.item.bank_code}
//           bank_name={props.item.bank_name}
//           description={props.item.description}
//           toggle={toggle}
//         />
//       )}
//     </Fragment>
//   )
// }
import { BaseTable } from "components"
import { DataList } from "../types"
import { InfoSection } from "./InfoSection"

interface PayableTableProps {  data?: DataList[] }

export const PayableTable:React.FC<PayableTableProps> = ({ data }) => (
    <div className="mt-5">
        <div><b>ACCOUNT PAYABLE</b></div>
        <div className="lg:w-[45%] ml-5"><InfoSection className="!justify-start" labelClass="lg:ml-5" label="Turnover Ratio" value={data?.[0]?.trade_name} /></div>

        <div className="overflow-x-auto mt-5">
            <BaseTable>
                <thead>
                    <tr>
                        <th>NO</th>
                        <th colSpan={2}>BILL ID#</th>
                        <th>REF PO ID#</th>
                        <th>DATE</th>
                        <th>VENDOR</th>
                        <th>BALANCE</th>
                        <th>7 DAYS</th>
                        <th>15 DAYS</th>
                        <th>21 DAYS</th>
                        <th>30 DAYS</th>
                    </tr>
                </thead>
                <tbody>
                    { data?.length === 0 ? <tr><td className="text-center" colSpan={11}><small>No Data Available</small></td></tr> : data?.map((val: any) => (
                        <tr>
                            <td className="w-[200px]">{val?.coa_code ?? "-"}</td>
                            <td className="w-[100px]">{val?.coa_code ?? "-"}</td>
                            <td>{val?.coa_code ?? "-"}</td>
                            <td className="w-[200px]">{val?.coa_code ?? "-"}</td>
                            <td className="w-[200px]">{val?.coa_code ?? "-"}</td>
                            <td className="w-[300px]">{val?.coa_code ?? "-"}</td>
                            <td>{val?.coa_code ?? "-"}</td>
                        </tr>
                    ))}
                </tbody>
            </BaseTable>
        </div>
    </div>
  )
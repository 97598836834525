import { Card } from "components";
import { CardSection } from "../CardSection";
import { AreaChart, GrossProfitMargin } from "../GrafikSection";

export function Efficiency() {
    return (
        <section>
            <span className="font-bold text-[15px]">Efficiency</span>
            <div className="grid lg:grid-cols-2 mt-2 gap-2">
                <div >
                    <Card>
                        <Card.Body>
                            <CardSection
                                label="Net profit margin"
                                value="-23,404%"
                                subLabel="96.1 % from 1 Jan - 3 Oct 2023"
                                persentase={96.1}
                            >
                                <div className="mt-[20px]" >
                                    <AreaChart 
                                        className="h-[150px]" 
                                        label={['Jan', 'Mar', 'May', 'Jul', 'Sept']}
                                        value={[400, 100, 200, 600, 300]}
                                        labelContent="income"
                                        backgroundColor="purple"
                                        borderColor="purple"
                                    />
                                </div>
                                <div className="mt-5">
                                    For every Rp 1 of sales revenue, an average net loss of Rp. 234.04 is made
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card>
                        <Card.Body>
                            <CardSection
                                label="Gross profit margin"
                                value="100%"
                                subLabel="No change"
                                persentase={0}
                            >
                                <div className="mt-[20px]" >
                                    <GrossProfitMargin className="h-[150px]" />
                                </div>

                                <div className="flex justify-center">
                                    Sales revenue: <strong>{(15658773).toLocaleString()}</strong>
                                </div>
                                <div className="mt-5">
                                    For every Rp 1 of sales revenue, an average gross profit of Rp. 1.00 is made
                                </div>
                            </CardSection>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </section>
    )
}
// React
import { useState } from "react"

// Components
import { Card, DateInput, FooterMenu, HeaderMenu, } from "components"
import { Efficiency, FinancialPosition, FinancialRatio, ProfitAndLost } from "./components/Section"

// Third-Party Libraries
import moment from "moment"

// Utils
import { getCompanyName, thisMonth, today } from "utils"

export default function Dashboard() {
    const thisMonthDate = thisMonth()
    const todayDate = today()

    const [filter, setFilter] = useState({
        dateFrom: thisMonthDate.fromDate,
        dateTo: todayDate
    })

    return (
        <section className="container my-5">
            <HeaderMenu title="ACCOUNT DASHBOARD" />
            <section className="mt-2">
                <div>
                    <Card>
                        <Card.Body>
                            <div className="border-b-2 pb-3">
                                <div className="lg:flex justify-between w-full">
                                    <div>
                                        <div className="flex items-center gap-4">
                                            <span className="font-bold text-[20px]">{getCompanyName()}</span>
                                            <span className="text-[14px] flex mt-1">For the period 1 jan 2024 - 3 oct 2024 - Accrual basis</span>
                                        </div>
                                        <div className="mt-3">
                                            <span>1 jan - 3 oct 2024 <b className="mx-5">-</b> 1 jan - 3 oct 2024 </span>
                                        </div>
                                    </div>
                                    <div className="join mt-2 lg:mt-0">
                                        <div className="lg:w-[150px]">
                                            <DateInput
                                                dateFormat=" d MMMM yyyy"
                                                minDate={moment(filter.dateFrom).toDate()}
                                                onChange={(date: any) => setFilter({ ...filter, dateFrom: date })}
                                                className="join-item w-[50px]"
                                                selected={moment(filter.dateFrom).toDate()}
                                            />
                                        </div>
                                        <div className="lg:w-[150px]">
                                            <DateInput
                                                dateFormat=" d MMMM yyyy"
                                                minDate={moment(filter.dateTo).toDate()}
                                                onChange={(date: any) => setFilter({ ...filter, dateTo: date })}
                                                className="join-item w-[100px]"
                                                selected={moment(filter.dateTo).toDate()}
                                            />
                                        </div>
                                        <div className="lg:w-[150px]">
                                            <select className="select select-bordered join-item">
                                                <option disabled selected>Fill By</option>
                                                <option value={1}>Prev Year</option>
                                                <option value={2}>This Year</option>
                                                <option value={3}>This Month</option>
                                                <option value={4}>This Week</option>
                                                <option value={5}>Today</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 px-2 overflow-x-auto">
                                {/* Profitablity section */}
                                <div>
                                    <ProfitAndLost />
                                </div>

                                {/* Efficiency section */}
                                <div className="mt-4">
                                    <Efficiency />
                                </div>

                                {/* Financial position and cash */}
                                <div className="mt-4">
                                    <FinancialPosition />
                                </div>

                                {/* Financial ratio */}
                                <div className="mt-4">
                                    <FinancialRatio />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </section>
            <section className="mt-2">
                <FooterMenu />
            </section>
        </section>
    )
}
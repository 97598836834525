// Components
import { Select } from "components"
import { Efficiency, FinancialPosition, FinancialRatio, ProfitAndLost } from "pages/Accounts/Dashboard/components/Section"

export default function Home(): JSX.Element {
  return (
    <section className="flex flex-col gap-5">
      <section className="flex items-center gap-3">
        <iframe className="w-[250px] aspect-video" src="https://www.youtube.com/embed/NZ4CzUd3BhU?si=N4mvEWbfSa02Yiaa" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

        <section className="h-fit">
          <div>Learn how to use new features</div>
          <div>Thanks for purchasing Analytics Plus. Here are some resources to help you get started with the new features</div>
          <br />
          <div>Learn more about, <strong>WATCH VIDEO...</strong></div>
        </section>
      </section>

      <div className="border-y py-3 flex flex-wrap justify-between items-center gap-3">
        <section>
          <div>HAWK ANALYTICS</div>
          <div className="text-3xl font-bold">BUSINESS SNAPSHOT</div>
        </section>

        <section className="flex gap-3">
          <div className="min-w-[200px]">
            <Select
              placeholder="PERIODE FILTER"
              options={[]}
            />
          </div>

          <div className="min-w-[200px]">
            <Select
              placeholder="COMPARISON FILTER"
              options={[]}
            />
          </div>
        </section>
      </div>

      <section>
        <div>GRAPH / TABLE CONTENT</div>

        <section className="flex flex-col gap-4">
          <ProfitAndLost />
          <Efficiency />
          <FinancialPosition />
          <FinancialRatio />
        </section>
      </section>
    </section>
  )
}
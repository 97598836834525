import { Fragment, useContext } from "react"
import { Delete, Update } from "../TableSection"
import { ListSubGroupTask } from "../../types"
import { dataContext } from "pages/Setup/Preference/Bank"
import { Loading, TableNumber } from "components"
import { TableType } from "../../types"
import { useDropdownGroupTask } from "../../utils"

export const TableSection = ({ data, isLoading, refetch }: TableType) => {
  const { currentPage, limit, size, total} = useContext(dataContext)
  const dataSubGroupTask: ListSubGroupTask[] = data.items

  const dataGroupTaskId = useDropdownGroupTask()

  return (
    <Fragment> 
        {isLoading || dataSubGroupTask.length === 0 
            ? (<tr><td colSpan={9} className="text-center"><Loading loading={isLoading} errorText="No data available" /></td></tr>) 
            : dataSubGroupTask?.map((item, key) => (
                <tr>
                    <td className="text-center">{TableNumber({dataLength: size, pageCurrent: currentPage, index: key + 1, limit: limit, totalData: total})}</td>
                    <td>{item.mr_group_sub_code ?? "-"}</td>
                    <td>{item.mr_group_task_id ? dataGroupTaskId?.data.find((task) => task?.value?.toString() === item?.mr_group_task_id?.toString())?.label : "-"}</td>
                    <td>{item.mr_group_sub_name ?? "-"}</td>
                    <td>{item.description}</td>
                    <td>
                        <div className="flex items-center justify-center">
                            <Update data={item} refetch={refetch} />
                            <Delete data={item} refetch={refetch} />
                        </div>
                    </td>
                </tr>
            ))
        }
    </Fragment>
  )
}
// React
import { Fragment, useContext } from "react"

// Contexts
import { ProductContext } from "pages/Purchase/Transaction/PurchaseOrder/components/DetailBody/contexts"

export function Product(params: { id: string }) {
  // Hooks
  const { data } = useContext(ProductContext)

  // Vars
  const selectedData = data.find(item => item.value.toString() === params.id)

  return (
    <Fragment>
      <td>{selectedData?.product_barcode}</td>
      <td>{selectedData?.product_name}</td>
      <td>{selectedData?.mr_unit_name}</td>
    </Fragment>
  )
}